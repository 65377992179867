<template>
  <div class="page">
    <van-nav-bar
      fixed
      :title="form.equipName + '(' + form.equipMachineNo + ')'"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="container">
      <div class="info-card">
        <van-row>
          <van-col span="24">
            <label class="info-label">实施编号: </label>
            <span>{{ form.execId }}</span>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <label class="info-label">计划编号: </label>
            <span>{{ form.planId }}</span>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <label class="info-label">计划类型: </label>
            <span>{{ form.planTypeName }}</span>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <label class="info-label">计划来源: </label>
            <span>{{ form.planSource }}</span>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <label class="info-label">计划保养日期: </label>
            <span>{{ form.executionDay }}</span>
          </van-col>
        </van-row>
      </div>
      <div class="info-tabs">
        <van-tabs v-model="lubActive">
          <van-tab name="basicInfo" title="基本信息">
            <van-form ref="form">
              <van-field
                v-model="form.execMainName"
                readonly
                clickable
                required
                name="execMainName"
                label="保养人"
                placeholder="点击选择保养人"
                input-align="right"
                :rules="[{ required: true, trigger: 'onChange' }]"
                maxlength="50"
                @click="editable ? (maintainerInfo.show = true) : ''"
              >
              </van-field>
              <van-field
                v-model="form.execDepartment"
                readonly
                clickable
                required
                name="execDepartment"
                label="保养部门"
                placeholder=""
                input-align="right"
              >
              </van-field>
              <van-field
                v-model="form.execBeginDate"
                readonly
                clickable
                required
                name="execBeginDate"
                label="保养开始时间"
                placeholder="点击选择时间"
                input-align="right"
                :rules="[{ required: true, trigger: 'onChange' }]"
                @click="dateTimePicker('execBeginDate')"
              >
              </van-field>
              <van-field
                v-model="form.execEndDate"
                readonly
                clickable
                required
                name="execEndDate"
                label="保养结束时间"
                placeholder="点击选择时间"
                input-align="right"
                :rules="[{ required: true, trigger: 'onChange' }]"
                @click="dateTimePicker('execEndDate')"
              >
              </van-field>
              <van-field
                v-model="form.execState"
                clickable
                required
                name="execMainNameList"
                label="执行情况"
                placeholder="请填写执行情况"
                input-align="right"
                :readonly="!editable"
                :rules="[{ required: true, trigger: 'onChange' }]"
                maxlength="50"
              >
              </van-field>
              <van-field
                v-model="form.maintenanTypeName"
                readonly
                clickable
                required
                name="maintenanTypeName"
                label="润滑保养类型"
                placeholder="点击选择润滑保养类型"
                input-align="right"
                :rules="[{ required: true, trigger: 'onChange' }]"
                @click="selectPicker('maintenanType', '润滑保养类型')"
              >
              </van-field>
              <van-field
                v-model="form.implementationCost"
                clickable
                type="number"
                name="implementationCost"
                label="保养费用(元)"
                placeholder="请填写保养费用(元)"
                input-align="right"
                :rules="[
                  {
                    pattern: /^-?(^\d{1,23})*(\.\d{1,6})?$/,
                    message: '仅能输入数字，允许输入23位整数 小数6位',
                    trigger: 'onChange'
                  }
                ]"
                :readonly="!editable"
              >
              </van-field>
              <van-field
                v-model="form.execLaborhour"
                clickable
                type="number"
                name="execLaborhour"
                label="工时(人*小时)"
                label-width="100"
                placeholder="请填写工时(人*小时)"
                input-align="right"
                :rules="[
                  {
                    pattern: /^-?(^\d{1,23})*(\.\d{1,4})?$/,
                    message: '仅能输入数字，允许输入23位整数 小数4位',
                    trigger: 'onChange'
                  }
                ]"
                :readonly="!editable"
              >
              </van-field>
              <van-field
                v-model="form.remark"
                clickable
                type="textarea"
                name="remark"
                label="备注"
                placeholder="请填写备注"
                input-align="right"
                maxlength="500"
                show-word-limit
                :readonly="!editable"
              >
              </van-field>
              <van-field
                readonly
                name="pictureList"
                label="保养图片"
                input-align="right"
              >
                <template #input>
                  <FileUploader
                    v-model="form.pictureList"
                    :max-count="5"
                    :max-size="1024 * 1024 * 100"
                    :accept="FILE_ACCEPT_TYPES.IMAGE"
                    :unable-upload="!editable"
                  />
                </template>
              </van-field>
              <!-- 上传附件	 -->
              <fileUploaderEncap
                v-model="form.annexList"
                accept="*"
                :max-size="1024 * 1024 * 100"
                :max-count="5"
                tip="格式不限,单个文件不超过100MB,仅可以上传5个文件"
                :unable-upload="!editable"
              >
              </fileUploaderEncap>
            </van-form>
          </van-tab>
          <van-tab name="lubProject" title="润滑保养项目">
            <div
              v-for="(item, idx) in form.mxList"
              :key="item.id"
              class="lubProject card"
              @click="gotoLubProjectEdit(idx)"
            >
              <van-row class="card-row">
                <div class="card-title">{{ item.projName }}</div>
                <div
                  v-if="item.pb === 0"
                  class="card-tip"
                  :style="{ background: '#5C7BA2' }"
                >
                  未实施
                </div>
                <div v-else class="card-tip" :style="{ background: '#3683F4' }">
                  已实施
                </div>
              </van-row>
              <van-row class="card-row">
                <van-col span="8">
                  <div class="card-text">
                    标准值: {{ item.standardValue || "--" }}
                  </div>
                </van-col>
                <van-col span="8">
                  <div class="card-text">上限: {{ item.upValue || "--" }}</div>
                </van-col>
                <van-col span="8">
                  <div class="card-text">
                    下限: {{ item.downValue || "--" }}
                  </div>
                </van-col>
              </van-row>
              <van-row class="card-row">
                <div class="card-address">
                  <img
                    class="card-icon"
                    src="@/assets/images/equipment/address.png"
                  />
                  <span class="date-text">{{ item.projectDesc }}</span>
                </div>
              </van-row>
            </div>
          </van-tab>
          <van-tab name="materials" title="用料">
            <div
              v-for="(item, idx) in form.itemList"
              :key="item.id"
              class="materials card"
              @click="gotoMaterialsEdit(idx)"
            >
              <van-row class="card-row">
                <div class="card-title">
                  {{ item.prodName }}({{ item.prodId }})
                </div>
              </van-row>
              <van-row class="card-row">
                <van-col span="6">
                  <div class="card-text-icon">
                    <img
                      class="card-icon"
                      src="@/assets/images/equipment/specs.png"
                    />
                    <div>{{ item.forPlastic || "--" }}</div>
                  </div>
                </van-col>
                <van-col span="6">
                  <div class="card-text-icon">
                    <img
                      class="card-icon"
                      src="@/assets/images/equipment/unit.png"
                    />
                    <div>{{ item.unitName || "--" }}</div>
                  </div>
                </van-col>
                <van-col span="6">
                  <div class="card-text-icon">
                    <img
                      class="card-icon"
                      src="@/assets/images/equipment/number.png"
                    />
                    <div>{{ item.quantity || "--" }}</div>
                  </div>
                </van-col>
                <van-col span="6">
                  <div class="card-text-icon">
                    <img
                      class="card-icon"
                      src="@/assets/images/equipment/brand.png"
                    />
                    <div>{{ item.brandName || "--" }}</div>
                  </div>
                </van-col>
              </van-row>
            </div>
            <div
              v-if="editable"
              class="materials card"
              @click="addItem('materials')"
            >
              <img class="additem" src="@/assets/images/tj.png" />
            </div>
          </van-tab>
          <van-tab name="tools" title="工具">
            <div
              v-for="(item, idx) in form.toolDTOS"
              :key="item.id"
              class="tools card"
              @click="gotoToolsEdit(idx)"
            >
              <van-row class="card-row">
                <div class="card-title">
                  {{ item.toolName }}({{ item.toolCode }})
                </div>
              </van-row>
              <van-row class="card-row">
                <van-col span="6">
                  <div class="card-text-icon">
                    <img
                      class="card-icon"
                      src="@/assets/images/equipment/specs.png"
                    />
                    <div>{{ item.classType || "--" }}</div>
                  </div>
                </van-col>
                <van-col span="6">
                  <div class="card-text-icon">
                    <img
                      class="card-icon"
                      src="@/assets/images/equipment/unit.png"
                    />
                    <div>{{ item.unitName || "--" }}</div>
                  </div>
                </van-col>
                <van-col span="6">
                  <div class="card-text-icon">
                    <img
                      class="card-icon"
                      src="@/assets/images/equipment/number.png"
                    />
                    <div>{{ item.count || "--" }}</div>
                  </div>
                </van-col>
                <van-col span="6">
                  <div class="card-text-icon">
                    <img
                      class="card-icon"
                      src="@/assets/images/equipment/brand.png"
                    />
                    <div>{{ item.brandName || "--" }}</div>
                  </div>
                </van-col>
              </van-row>
            </div>
            <div v-if="editable" class="tools card" @click="addItem('tools')">
              <img class="additem" src="@/assets/images/tj.png" />
            </div>
          </van-tab>
        </van-tabs>
        <!--时间选择器-->
        <van-popup v-model="showDateTimePicker" position="bottom">
          <van-datetime-picker
            v-model="datePickerVal"
            title="选择年月日小时"
            type="datehour"
            @confirm="onDateTimePickerConfirm"
            @cancel="showDateTimePicker = false"
          />
        </van-popup>
        <select-popup
          :visible="showSelectPicker"
          :choose-value="showSelectPickerKey"
          :title="showSelectPickerTitle"
          :data="options"
          value-key="value"
          label-key="label"
          :popup-style="{ height: '50vh' }"
          @confirm="onSelectPickerConfirm"
          @close="showSelectPicker = false"
        />
        <SelectPopupGrade
          v-model="form.maintainer"
          :immediately="false"
          :title="maintainerInfo.title"
          :list.sync="maintainerInfo.list"
          :visible="maintainerInfo.show"
          @change="val => userCchange(val)"
          @close="maintainerInfo.show = false"
        />
        <select-person
          :title="maintainerInfo.title"
          :visible="maintainerInfo.show"
          :choose-value="maintainerInfo.list"
          :show-department-name="false"
          :show-phone-number="true"
          position="bottom"
          :include-children="true"
          :multiple="true"
          department-disabled-key="--"
          @close="maintainerInfo.show = false"
          @confirm="maintainerChoose"
        />
      </div>
    </div>
    <bottom-fixed-box>
      <van-button v-if="editable" type="info" @click="save()">
        保存
      </van-button>
    </bottom-fixed-box>
  </div>
</template>

<script>
import {
  getDeviceLubImplementInfo,
  getBasicParams,
  updateLubImelpment
} from "./api";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import { formatDates } from "@/views/accidentReport/utils/formDate";
import FileUploader from "@/components/fileuploader/FileUploader";
import fileUploaderEncap from "@/components/fileUploaderEncap.vue";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";

export default {
  name: "DeviceLubImplementEdit",
  components: {
    BottomFixedBox,
    FileUploader,
    fileUploaderEncap
  },
  data() {
    return {
      FILE_ACCEPT_TYPES,
      id: this.$route.params.id,
      type: this.$route.params.type,
      editable: false,
      form: {
        execMainNameList: []
      },
      datePickerVal: new Date(),
      lubActive: "baseInfo",
      maintainerInfo: {
        show: false,
        title: "请选择保养人",
        list: []
      },
      showDateTimePickerKey: "",
      showDateTimePicker: false,

      showSelectPickerKey: "",
      showSelectPickerTitle: "",
      showSelectPicker: false,
      options: [1, 2],
      asyncOptions: {
        maintenanType: [], //保养类型
        brand: [] //品牌
      }
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.editable = this.type === "detail" ? false : true;
      this.getLubInfo();
      this.getOptions("lubMainType", "maintenanType"); //保养类型
      this.getOptions("brand", "brand"); //品牌
    },
    addItem(type) {
      if (type === "materials") {
        this.form.itemList.push({ execId: this.form.execId });
        this.gotoMaterialsEdit(
          this.form.itemList.length > 0 ? this.form.itemList.length - 1 : 0
        );
      } else {
        this.form.toolDTOS.push({ execId: this.form.execId });
        this.gotoToolsEdit(
          this.form.toolDTOS.length > 0 ? this.form.toolDTOS.length - 1 : 0
        );
      }
    },
    save() {
      this.$refs.form.validate().then(async () => {
        await updateLubImelpment(this.form);
        this.$toast.success("保存成功！");
      });
    },
    getLubInfo() {
      getDeviceLubImplementInfo(this.id).then(res => {
        this.form = res;
        this.maintainerInfo.list = this.form.execMainList;
        for (let i = 0; i < this.form.execMainList; i++) {
          let per = {
            label: this.form.execMainNameList[i],
            value: this.form.execMainLis[i]
          };
          this.maintainerInfo.list.push(per);
        }
        this.form.execMainName = this.form.execMainNameList?.toString();
      });
    },
    // 根据基础编码获取基础数据
    getOptions(codeId, prop) {
      getBasicParams(codeId).then(res => {
        this.asyncOptions[prop] = res.unDelete;
      });
    },
    //保养人选择
    maintainerChoose(Id, maintainerAllInfo) {
      this.maintainerInfo.list = maintainerAllInfo;
      let execMain = []; //保养人
      let execMainNameList = []; //保养人id
      let execDepartment = []; //保养人部门名称
      let execDepartmentCode = []; //保养人部门id
      for (const maintainerAllInfoElement of maintainerAllInfo) {
        execMain.push(maintainerAllInfoElement.id);
        execMainNameList.push(maintainerAllInfoElement.name);
        execDepartment.push(maintainerAllInfoElement.departmentName);
        execDepartmentCode.push(maintainerAllInfoElement.department);
      }
      this.form.execMain = execMain.toString();
      this.form.execMainName = execMainNameList.toString();
      this.form.execMainNameList = execMainNameList;

      let newExecDepartment = [...new Set(execDepartment)];
      let newExecDepartmentCode = [...new Set(execDepartmentCode)];
      this.form.execDepartment = newExecDepartment.toString();
      this.form.execDepartmentCode = newExecDepartmentCode.toString();
    },
    dateTimePicker(key) {
      if (!this.editable) return;
      this.showDateTimePickerKey = key;
      this.showDateTimePicker = true;
    },
    onDateTimePickerConfirm(val) {
      let datetime = formatDates(val, "yyyy-MM-dd hh:mm:ss");
      if (this.showDateTimePickerKey === "execEndDate") {
        if (datetime < this.form.execBeginDate) {
          this.$toast.fail("结束时间不能大于开始时间！");
          return;
        }
      }
      this.$set(this.form, this.showDateTimePickerKey, datetime);
      this.showDateTimePicker = false;
    },
    selectPicker(key, title) {
      if (!this.editable) return;
      this.options = this.asyncOptions[key];
      this.showSelectPickerKey = key;
      this.showSelectPickerTitle = title;
      this.showSelectPicker = true;
    },
    onSelectPickerConfirm(val) {
      this.$set(this.form, this.showSelectPickerKey, val.value);
      this.$set(this.form, this.showSelectPickerKey + "Name", val.label);
      this.showSelectPicker = false;
    },
    //跳转页面
    gotoLubProjectEdit(idx) {
      this.$router.push({
        name: "lubProjectEdit",
        params: { form: this.form, idx: idx, editable: this.editable }
      });
    },
    //跳转页面
    gotoMaterialsEdit(idx) {
      this.$router.push({
        name: "materialsEdit",
        params: { form: this.form, idx: idx, editable: this.editable }
      });
    },
    //跳转页面
    gotoToolsEdit(idx) {
      this.$router.push({
        name: "toolsEdit",
        params: { form: this.form, idx: idx, editable: this.editable }
      });
    },
    navBack() {
      if (this.editable) {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "数据尚未保存，确认返回？"
          })
          .then(() => {
            this.$router.back();
          });
        return;
      }
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="scss">
.page {
  height: 750px;
  padding-bottom: 58px;
  overflow: hidden;
}

.container {
  height: 750px;
  margin-top: 58px;
  overflow: hidden;

  .info-card {
    width: 343px;
    height: 168px;
    line-height: 28px;
    color: #2e2e4d;
    background: #f5f7fa;
    margin: 13px 16px 0;
    padding-top: 12px;
    border-right: 6px;
    font-size: 14px;

    .info-label {
      display: inline-block;
      width: 98px;
      color: #8c8f97;
      margin: 0 0 5px 16px;
    }
  }

  .info-tabs {
    ::v-deep {
      .van-tabs__wrap {
        padding: 0 8px;
      }

      .van-tabs__content {
        height: 450px;
        background: #eceef2;
        overflow-y: auto;
        box-sizing: border-box;
      }
    }

    .lubProject {
      height: 100px;
    }

    .card {
      width: 100%;
      margin-bottom: 8px;
      padding: 12px 16px;
      background: #ffffff;
      box-sizing: border-box;

      .additem {
        width: 50px;
        height: 50px;
        margin: 0 0 0 150px;
      }

      .card-row {
        height: 18px;
        line-height: 18px;
        margin-bottom: 10px;
        font-size: 14px;

        > div {
          float: left;
        }

        .card-title {
          width: 112px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .card-tip {
          font-size: 11px;
          padding: 0 4px;
          color: #ffffff;
          border-radius: 2px;
        }

        .card-text {
          font-size: 12px;
          color: #a0a2a6;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .card-icon {
          height: 15.23px;
          width: 15.23px;
          margin: 0 4px 0 0;
          vertical-align: middle;
        }

        .card-text-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #a0a2a6;

          > div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .card-address {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #a0a2a6;

          .date-text {
            width: 320px;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
